import React from 'react'
import Image from '../Image';

const Videos = ({data}) => {
    return (
        <section data-section-id="video-thumbnails">
            <div className="gc row-gap-80 m-row-gap-40">
                {data.VideoBlock ?
                    data.VideoBlock.map((video, i) => (
                        <div className="span-4 t-span-6 m-span-12 card">
                            <a href={video.VideoUrl} target="_blank">
                                <div className="auto-layout auto-layout-20">
                                    <Image source={video.VideoThumbnail[0]} ratio="16-9" />
                                    <div className="grey small">{video.VideoDuration}</div>
                                    <div>
                                    {video.VideoSpeaker ?
                                        <p>{video.VideoSpeaker}</p>
                                    :null}
                                    {video.VideoTitle ?
                                        <p>{video.VideoTitle}</p>
                                    :null}
                                    </div>
                                    {video.VideoSummary ?
                                        <p>{video.VideoSummary}</p>
                                    :null}
                                    {video.VideoLinkText ?
                                        <p className="green">{video.VideoLinkText}</p>
                                    :null}
                                </div>
                            </a>
                        </div>
                    ))
                :null}
            </div>
        </section>
    )
}

export default Videos;