import React from 'react'

import Image from '../Image'

const BlogImage = ({data}) => {
    return (
        <div 
            className="blog-image"
            data-full-width={data.fullWidth}>
            {data.Image ? 
                <Image 
                    source={data.Image} 
                    ratio='auto'
                />
            : null}
        </div>
    )
}

export default BlogImage