import React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from '../Image';
import HashLink from '../../utils/HashLink';

const ParagraphWithIcons = ({ data }) => {
    let border_top='';
    let spacing='';
    if(data.TopGreyLine !== false) {
        border_top='border-top-light-grey';
    }
    if(data.SpacingTop === false) {
        spacing+='spacing-top-0';
    }
    if(data.SpacingBottom === false) {
        spacing+=' spacing-bottom-40';
    }
    return (
        <section className={`paragraph ${border_top} ${spacing}`} data-section-id="paragraph-with-title">
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                {data.Title ? 
                    <div className="d-1-7 m-1-13">
                        <h3>{data.Title}</h3>
                    </div>
                : null}
                <div className="d-7-13 m-1-13">
                    <div className="auto-layout auto-layout-20">
                        {data.Icon ?
                            <div className={`icon-grid row-gap-80 m-row-gap-20 gc-${data.IconRowCount ? data.IconRownCount : '3'}-col t-gc-2-col m-gc-2-col`}>
                                {data.Icon.map((icon,i) => (
                                    icon.Icons.map((image, j) => (
                                        <div className="icon--image flex flex-column auto-layout auto-layout-10">
                                            <img src={image.url} alt={image.alternativeText} />
                                            {icon.IconTitle ?
                                                <p className="text-center">{icon.IconTitle}</p>
                                            :null}
                                        </div>
                                    ))
                                ))}
                            </div>
                        : null}
                        {data.RichText ?
                            <ReactMarkdown>{data.RichText.Paragraph}</ReactMarkdown>
                        : null}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ParagraphWithIcons