import React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from '../Image';
import HashLink from '../../utils/HashLink';

const TwoColumnsWithTitle = ({ data }) => {
    let border_top='';
    let spacing='';
    let all_elements='';
    if(data.TopGreyLine !== false) {
        border_top='border-top-light-grey';
    }
    if(data.SpacingTop === false) {
        spacing+='spacing-top-0';
    }
    if(data.SpacingBottom === false) {
        spacing+=' spacing-bottom-40';
    }
    let columnOne=[];
    let columnTwo=[];

    if(data.Column) {
        data.Column.map((c, i) => (
            c.ColumnNumber === 1 ? 
                columnOne.push(c) : columnTwo.push(c)
        ))
    }
    return (
        <section className={`paragraph ${border_top} ${spacing}`}  data-section-id="paragraph-with-two-columns" style={{ "--borderTopColour": data.Colour}}>
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                {data.Title ? 
                    <div className="span-6 m-span-12">
                        <h3>{data.Title}</h3>
                    </div>
                : null}
                {data.IntroParagraph !== null ?
                    <div className="d-7-13 m-1-13">
                        <ReactMarkdown>{data.IntroParagraph}</ReactMarkdown>
                    </div>
                :null}
                <div className="d-7-10 m-1-13 column flex flex-column gap-40">
                    {columnOne.map((c,i) => (
                        <div className="auto-layout auto-layout-20 column-contents" style={c.BorderColour ? { borderTop: `4px solid ${c.BorderColour}`, paddingTop: '2rem'} : { paddingTop: '0' }}>
                            <ReactMarkdown>{c.Text}</ReactMarkdown>
                        </div>
                    ))}
                </div>
                <div className="d-10-13 m-1-13 column flex flex-column gap-40">
                    {columnTwo.map((c,i) => (
                        <div className="auto-layout auto-layout-20 column-contents" style={c.BorderColour ? { borderTop: `4px solid ${c.BorderColour}`, paddingTop: '2rem'} : { paddingTop: '0' }}>
                            <ReactMarkdown>{c.Text}</ReactMarkdown>
                        </div>
                    ))}
                </div>
                { data.HeroImageBottom ?
                    <div className="d-7-13 m-1-13">
                        <Image source={data.HeroImageBottom} ratio="3-2" />
                    </div>
                :null}
            </div>
        </section>
    )
}

export default TwoColumnsWithTitle